import React, { useContext } from "react";
import { SignIn, SignUp, Home } from "../pages";
import QueryInfo from "../components/query/QueryInfo/QueryInfo";
import { Switch, Route, Redirect } from "react-router-dom";
import { Routes } from "../configuration";
import ServicesContext, { Context } from "../services/ServicesContext";
import RedirectToHtmlPage from "../pages/Index/index";

export default function () {
  return (
    <Switch>
      <UnauthenticatedRoute path={Routes.signIn} exact={true}>
        <SignIn />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path={Routes.index} exact={true}>
        <RedirectToHtmlPage />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path={Routes.signUp} exact={true}>
        <SignUp />
      </UnauthenticatedRoute>
      <AuthenticatedRoute path={Routes.list} exact={true}>
        <Home />
      </AuthenticatedRoute>
      <AuthenticatedRoute path={Routes.queryInfo} exact={true}>
        <QueryInfo />
      </AuthenticatedRoute>
    </Switch>
  );
}

const AuthenticatedRoute = (props: any) => {
  const { children, ...rest }: { children: JSX.Element } = props;
  const accountService = useContext<Context>(ServicesContext).accountService;

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          accountService.isTokenExist() ? (
            children
          ) : (
            <Redirect
              to={`${Routes.signIn}?redirect=${props.location.pathname}${props.location.search}`}
            />
          )
        );
        }
      }
    />
  );
};

const UnauthenticatedRoute = (props: any) => {
  const { children, ...rest }: { children: JSX.Element } = props;
  const accountService = useContext<Context>(ServicesContext).accountService;

  return (
    <Route
      {...rest}
      render={(props) =>
        !accountService.isTokenExist() ? children : <Redirect to="/list" />
      }
    />
  );
};
