import { AppBar, Box, makeStyles, Tab, Tabs } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import * as React from "react";
import { useEffect, useState } from "react";
import { DatabaseType } from "../components/query/dtoInterfaces";
import ResultGrid from "../components/query/ExcuteQuery/ResultGrid";
import api from "../dataProvider/api";
import AQB from "./aqb.client";

interface QueryBuilderProps {
  name: string;
  setStartQuery?: any;
  sqlTextHandler?: (clientSQL: any, SQL: string) => void;
  databaseType: DatabaseType
  setQueryBuilderReady?: React.Dispatch<React.SetStateAction<boolean>>;
}

export class QueryBuilder extends React.Component<QueryBuilderProps> {
  constructor(props: QueryBuilderProps) {
    super(props)
  
    this.name = this.props.name;
  }
  
  name: string;
  querybuilder: HTMLDivElement | null | undefined;
  treeview: HTMLDivElement | null | undefined;
  navbar: HTMLDivElement | null | undefined;
  canvas: HTMLDivElement | null | undefined;
  statusbar: HTMLDivElement | null | undefined;
  grid: HTMLDivElement | null | undefined;
  sql: HTMLDivElement | null | undefined;
  ssql: HTMLDivElement | null | undefined;

  componentDidMount() {
    this.name = this.props.name;

    //@ts-ignore
    window.AQB = AQB;

    const opts = { language: "auto" };

    AQB.Web.UI.QueryBuilder(this.name, this.querybuilder, opts);
    AQB.Web.UI.ObjectTreeView(this.name, this.treeview);
    AQB.Web.UI.SubQueryNavigationBar(this.name, this.navbar);
    AQB.Web.UI.Canvas(this.name, this.canvas);
    AQB.Web.UI.StatusBar(this.name, this.statusbar);
    AQB.Web.UI.Grid(this.name, this.grid);
    AQB.Web.UI.SqlEditor(this.name, this.sql, { readOnly: true });
    AQB.Web.UI.SqlEditor(this.name, this.ssql, { targetQueryPart: 'SubQuery', readOnly: true });

    AQB.Web.onQueryBuilderReady(this.subscribeToQueryBuilderChanges);
    AQB.Web.UI.startApplication(`/QueryBuilder/CreateQueryBuilder?DatabaseType=${this.props.databaseType}`, this.name);
  }

  subscribeToQueryBuilderChanges = (qb: any) => {
    if (this.props.setQueryBuilderReady)
      this.props.setQueryBuilderReady(true);

    if (this.props.sqlTextHandler) {
      qb.on(qb.Events.SQLTextChanged, () =>
        this.props.sqlTextHandler && this.props.sqlTextHandler(qb.clientSQL, qb.SQL.replaceAll("\r", ""))
      );
      qb.on(qb.Events.SQLUpdated, () => {
        this.props.sqlTextHandler && this.props.sqlTextHandler(qb.clientSQL, qb.SQL.replaceAll("\r", ""))
      });
    }
  };

  componentWillUnmount() {
    AQB.Web.dispose();
  }

  render() {
    return (
      <div>
        <div id="qb" ref={(el) => (this.querybuilder = el)}></div>
        <div className="qb-ui-layout">
          <div className="qb-ui-layout__top">
            <div className="qb-ui-layout__left">
              <div className="qb-ui-structure-tabs">
                <div className="qb-ui-structure-tabs__tab">
                  <input
                    type="radio"
                    id="tree-tab"
                    name="qb-tabs"
                    defaultChecked
                  />
                  <label htmlFor="tree-tab">Database</label>
                  <div className="qb-ui-structure-tabs__content">
                    <div ref={(el) => (this.treeview = el)}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="qb-ui-layout__right">
              <div ref={(el) => (this.navbar = el)}></div>
              <div ref={(el) => (this.canvas = el)}></div>
              <div ref={(el) => (this.statusbar = el)}></div>
              <div ref={(el) => (this.grid = el)}></div>
            </div>
          </div>
          <TabsSql sqlRef={<div ref={(el) => (this.sql = el)}></div>} ssqlRef={<div ref={(el) => (this.ssql = el)}></div>} />
        </div>
      </div>
    );
  }
}

const TabsSql = ({ sqlRef, ssqlRef }: { sqlRef: JSX.Element, ssqlRef: JSX.Element }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [error, setError] = useState<boolean>(false);
  // const qb = AQB.Web.QueryBuilder;

  const handleChange = (event: any, newValue: any) => {
    if (AQB.Web.QueryBuilder.clientSQL.replaceAll("\r", "") !== AQB.Web.QueryBuilder.SQL.replaceAll("\r", "") || AQB.Web.QueryBuilder?.SQLError) {
      AQB.Web.QueryBuilder.setSql(AQB.Web.QueryBuilder.clientSQL, (res: any) => {
        setError(res.SQLEditorError !== undefined);
      });
    }
    setValue(newValue);
  };

  useEffect(() => {
    setError(AQB.Web.QueryBuilder?.SQLError !== null && AQB.Web.QueryBuilder?.SQLError != undefined);
  }, [AQB.Web.QueryBuilder?.SQLError])

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label={<div>Sql query text {error && <Warning color="error" />}</div>} {...a11yProps(0)} />
          <Tab label="Current subquery text" {...a11yProps(1)} />
          <Tab label="Current subquery data preview" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className="qb-ui-layout__bottom">
          {sqlRef}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="qb-ui-layout__bottom">
          {ssqlRef}
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        {value === 2 ?
          <ResultGrid
            getTypeColumns={api.getTypeColumns}
            executeQuery={api.executeQuery}
            isSubQuery
          />
          :
          undefined
        }
      </TabPanel>
    </div>
  );
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {(
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface TabPanelProps {
  children?: JSX.Element,
  index: any,
  value: any,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    "& > header": {
      backgroundColor: "#3d566d",
      "& .MuiTab-wrapper": {
        textTransform: "capitalize"
      }
    }
  },
}));